import { render, staticRenderFns } from "./check.vue?vue&type=template&id=49f630bf&scoped=true&"
import script from "./check.vue?vue&type=script&lang=js&"
export * from "./check.vue?vue&type=script&lang=js&"
import style0 from "./check.vue?vue&type=style&index=0&id=49f630bf&prod&lang=less&"
import style1 from "./check.vue?vue&type=style&index=1&id=49f630bf&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f630bf",
  null
  
)

export default component.exports